import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';


class ContainerComponent extends React.Component {
  
  render() {

    return <Wrapper>{this.props.children}</Wrapper>
    
  }
}

export default ContainerComponent;

const Wrapper = styled.div`${tw`m-auto max-w-3xl`}`
