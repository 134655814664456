import axios from 'axios';
import {
  isBrowser,
  isAuthenticated,
  getAccessToken
} from './auth.service';

const CreateItem = async (val) => {
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/item`;
  const bearer = 'Bearer ' + getAccessToken();
  const data = { title: val }; 
  const options = {
    url: url,
    method: 'post',
    headers: { 'Authorization': bearer },
    data: data
  }
  let result;
  try {
    result = await axios(options);
  } catch (err) {
    return Promise.reject(err);
  }

  return result.data;
}

const FetchItem = async (itemSlug) => {
  
  // If user is logged in
  // if (isBrowser && !isAuthenticated()) {
  //   return Promise.reject('Not authenticated');
  // }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/item/${itemSlug}`;
  const bearer = 'Bearer ' + getAccessToken();
  const options = {
    url: url,
    method: 'get',
    headers: { 'Authorization': bearer }
  }
  let result;
  try {
    result = await axios(options);
  } catch (err) {
    return Promise.reject(err);
  }

  return result.data;
}

const FetchItems = async () => {
  
  const url = `${process.env.GATSBY_M101_API_URL}/item`;
  const bearer = 'Bearer ' + getAccessToken();
  const options = {
    url: url,
    method: 'get',
    headers: { 'Authorization': bearer }
  }
  let result;
  try {
    result = await axios(options);
  } catch (err) {
    return Promise.reject(err);
  }

  return result.data;
}

const UpdateItem = async (id, data) => {
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/item/${id}`;
  const bearer = 'Bearer ' + getAccessToken();

  let result;
  try {
    result = await axios({
      method: 'put',
      url: url,
      headers: { 'Authorization': bearer },
      data: data
    });
  } catch (err) {
    console.warn('Error fetching user', err);
    return Promise.reject(err);
  }

  return result.data;
}

/**
 *
 **/
const FetchList = async (userSlug) => {
  // If user is logged in
  if (!isBrowser) {
    return;
  }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/list/${userSlug}`;
  const bearer = 'Bearer ' + getAccessToken();
  
  let result;
  try {
    result = await axios({
      method: 'get',
      url: url,
      headers: {
        'Authorization': bearer
      }
    });
  } catch (err) {
    console.warn('Error fetching user', err);
    return Promise.reject(err);
  }
  return result.data;
}

const ToggleItem = async (id, newStatus) => {
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/item/${id}`;
  const bearer = 'Bearer ' + getAccessToken();

  let result;
  try {
    result = await axios({
      method: 'put',
      url: url,
      headers: { 'Authorization': bearer },
      data: {
        done: newStatus
      }
    });
  } catch (err) {
    console.warn('Error fetching user', err);
    return Promise.reject(err);
  }

  return result.data;
}

const DestroyItem = async (id) => {
  // If user is logged in
  if (isBrowser && !isAuthenticated()) {
    return Promise.reject('Not authenticated');
  }
  // Fetch userdata from api
  const url = `${process.env.GATSBY_M101_API_URL}/item/${id}`;
  const bearer = 'Bearer ' + getAccessToken();

  let result;
  try {
    result = await axios({
      method: 'delete',
      url: url,
      headers: { 'Authorization': bearer }
    });
  } catch (err) {
    console.warn('Error fetching user', err);
    return Promise.reject(err);
  }

  return result.data;
}

export {
  CreateItem,
  FetchItems, 
  FetchItem,
  UpdateItem,
  DestroyItem,
  FetchList,
  ToggleItem
}
