import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/shared/layout'
import SEO from '../components/shared/seo'
import { FetchItem } from '../utils/list.service'
import MakeItemComponent from '../components/makeItem/makeItemPage/MakeItemPageComponent'

class ItemPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      item: null
    }
  }

  // Fetch url param from location path 
  fetchParam(pathname) {
    return pathname.split('/').filter(Boolean).pop()
  }

  // Fetch toMake item by slug 
  fetchItem(itemSlug) {
    this.setState({ loading: true })
    FetchItem(itemSlug).then(item => {
      this.setState({item: item})
    }).catch(err => {
      console.warn(err)
      this.setState({err: err})
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  // Get the item after component mount 
  componentDidMount() {
    this.fetchItem(this.fetchParam(this.props.location.pathname));
  }

  render() {
    const {location} = this.props; 
    if (!location) { navigate('/'); }
    const itemSlug = this.fetchParam(location.pathname);

    const title = () => {
      if (this.state.item) { return this.state.item.title}
      else { return ''}
    }
    
    return (
      <Layout>

        <SEO title={title()} />
        
        <Container>
          { this.state.loading && (<p>Loading item</p>)}
          { !this.state.loading && !this.state.item && (
            <p>Item <em>{itemSlug}</em> not found</p>
          )}
          { !this.state.loading && this.state.item && (
            <MakeItemComponent item={this.state.item} />
          )}

        </Container>
      </Layout>
    )
  }    
}

const Container = styled.div`${tw`max-w-lg m-auto py-16`}`

export default ItemPage; 
