// https: //github.com/tastejs/todomvc/blob/gh-pages/examples/react/js/todoItem.jsx

import React from 'react'
import * as moment from 'moment'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import {Link, navigate } from 'gatsby'
import Container from '../../../shared/Container'
import { DestroyItem, ToggleItem } from '../../../../utils/list.service'
import { getUserInfo } from '../../../../utils/user.service'

class MakeItemPageComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user: getUserInfo()
    }
  }

  componentDidMount() {
    
  }
  
  // Toggle the done status of an item 
  toggle = (evt) => {
    
    const id = parseInt(evt.target.value); 
    const index = this.state.items.findIndex(element => element.id === id); 
    
    ToggleItem(this.state.items[ index].id, !this.state.items[ index].done)
      .then(result => {
        let s = this.state.items;
        s[ index].done = !s[ index].done;
        this.setState({ items: s })
      })
      .catch(err => { console.warn(err); });
  }
  
  /**
   * 
   * 
   * 
   */
  destroy = (evt) => {

    const id = parseInt(evt.target.value)
    const index = this.state.items.findIndex(element => element.id === id); 
    
    DestroyItem(this.state.items[ index].id)
      .then(result => {
        let a = this.state.items;
        a.splice(index, 1); 
        this.setState({ items: a })
      })
      .catch(err => console.warn(err)); 
  }

  
  canEdit = () => {
    return (
      this.props.item && 
      this.props.item.user && 
      (this.props.item.user.id === this.state.user.id)) 
  }

  render() {
    
    const { item } = this.props
    if (!item) { 
      navigate('/')
      return null
    }
    console.log(this.canEdit())

    return (
      <Container>
        {this.canEdit() && (
          <div style={tw`text-right`}><Link to={`/edit/item/${item.slug}`}>edit</Link></div>
        )}
        <ListBtn to={`/list/${item.user.slug}`}>Go to the list for this item</ListBtn>
        <p><img src={item.user.picture} height="20" style={{borderRadius: '50%', verticalAlign: 'middle'}} alt="avatar"></img> <strong>{item.user.nickname}</strong> created this item on <em>{moment(item.createdAt).format('DDD MMMM YYYY')}</em></p>
        <Title>Make Item: {item.title}</Title>
        <p>Status: {item.done === true ? 'Done' : 'Not done'}</p>
        {item.description && (
          <DescriptionWrapper>{item.description}</DescriptionWrapper>
        )}

        <CommentsWrapper>
          <h3>Comments</h3>
          <p>No comment yet</p>
        </CommentsWrapper>
      </Container>
    ); 
  }
}

const Title = styled.h1`${tw`break-words`}`
const ListBtn = styled(Link)`${tw`inline-block bg-orange-600 text-sm p-2 mb-2 no-underline text-white border-0 cursor-pointer rounded hover:bg-orange-500`}`
const DescriptionWrapper = styled.div`${tw`py-2 text-lg`}`
const CommentsWrapper = styled.div`${tw`my-16 p-4 bg-orange-100`}`

export default MakeItemPageComponent; 